<template>
  <div>
    <h2>Relatório SWAP</h2>
    <div class="row" v-if="!showModalEspera">
      <div class="col-md-3">
        <flat-pickr
          v-model="dataReferencia"
          class="form-control bg-white"
          :config="dateFilterConfig"
          placeholder="Data referência"
        />
      </div>
      <div class="col-md-2">
        <button @click="getRelatorio" class="btn btn-secondary">Exportar relatório</button>
      </div>
    </div>
    <div v-else>
      <div class="d-flex justify-content-center flex-column align-items-center">
        <feather-icon icon="AlertTriangleIcon" size="50" class="wr-icon mb-2"></feather-icon>
        <h3 class="mb-2">Montando relatorio de Liquidação...</h3><br>
        <img class="loadingImg" src="@/assets/images/loading.gif" height="60" width="60">
      </div>
    </div>
  </div>
</template>

<script>
import http from '/src/services/http'
import { BCard } from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import downloadFile from '@/utils/download-file'
// import { formatDate } from '@core/utils/filter'

export default {
  name: 'RelatorioSwap',
  components: {
    BCard,
    vSelect,
    flatPickr
  },
  data() {
    return {
      showModalEspera: false,
      errors: [],
      dateFilterConfig: {
        locale: Portuguese,
        dateFormat: 'd/m/Y',
      },
      dataReferencia: '',
    }
  },
  mounted() {

  },
  methods: {
    async getRelatorio() {
      this.showModalEspera = true
      this.showModalTermo = true
      const { data } = await http.get(`/recebiveis/relatorios/swap`, {
        responseType: 'blob',
        headers: { accept: 'application/json' },
        params: {
          data_referencia: this.dataReferencia,
        },
      })
      downloadFile(data, `relatorio_swap.xlsx`, 'text/xlsx')
      this.showModalEspera = false
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.v-select {
  width: 260px;
  background: white;
  margin-bottom: 28px;
  margin-left: auto;
  margin-top: -55px;
}

.assignor-selector {
  min-width: 240px;
}

</style>
